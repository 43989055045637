<template>

    <div class="flex flex-col space-y-2">

        <div class="font-medium text-gray-700">
            {{ component.data.subHeading }}
        </div>

        <div class="flex -space-x-px">

            <PushButtonSpinner v-for="(option, index) of component.data.options"
                               :key="option.label"
                               class="flex-1 text-gray-500 focus:z-20 focus:!ring-0"
                               :class="{
                                   'primary-border z-10 font-bold': option.value === model,
                                   'rounded-r-none': index === 0,
                                   'rounded-l-none': index === component.data.options.length - 1,
                                   'rounded-none': index !== 0 && index !== component.data.options.length - 1,
                               }"
                               :font-size="PushButtonFontSize.Small"
                               theme="white"
                               :state="getState(option.value)"
                               @click="model = option.value">
                {{ option.label }}
            </PushButtonSpinner>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { ButtonGroupCompact } from '../../types/Components'
    import PushButtonSpinner from '../buttons/PushButtonSpinner.vue'
    import { PushButtonState, PushButtonFontSize, PushButtonSize } from '../../lib/enums/PushButton'
    import { restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepButtonGroupCompact',
        components: { PushButtonSpinner },
        props: {
            component: { type: Object as PropType<ButtonGroupCompact>, required: true },
        },
        emits: [ 'progress' ],
        setup({ component }, { emit }) {

            const model = ref<string | null>()

            onMounted(() => {

                restoreState(model, [ 'data', component.data.name ])

                if (!model.value) {
                    model.value = component.data.options.find(option => option.isDefault)?.value
                }

            })

            function getState(option: string): PushButtonState {

                if (option === model.value) {
                    return PushButtonState.Selected
                }

                return PushButtonState.Active

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function isValid(): boolean {

                if (component.data.required === false) {
                    return true
                }

                return model.value !== undefined

            }

            return {
                PushButtonSize,
                PushButtonFontSize,
                model,
                fill,
                isValid,
                getState,
            }

        },
    })

</script>
