<template>

    <div class="px-4 h-full">

        <div v-if="flow && flow.total > 1" class="min-h-12 mb-6 flex items-center justify-center">
            <FlowCircles :flow="flow"/>
        </div>

        <TransitionLeftToRight>

            <div :key="currentStep.id" class="flex flex-col items-center justify-center">

                <slot/>

                <RequestImmediateAssistance :content="content" class="w-full max-w-md"/>

                <TCPA :content="content" class="w-full max-w-md"/>

                <PhoneBox v-if="content.showPhoneBox"
                          :text="content.phoneBoxText || undefined"
                          class="bg-gray-200 bg-opacity-50 text-center p-3 px-2 text-gray-800 rounded-lg shadow-[0_0_5px_rgba(0,0,0,0.15)] w-full max-w-md mt-2"/>

            </div>

        </TransitionLeftToRight>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Flow } from '../../types/Flow'
    import FlowCircles from '../flow/FlowCircles.vue'
    import { WebFormStep } from '../../types/State'
    import RequestImmediateAssistance from './content/RequestImmediateAssistance.vue'
    import TCPA from './content/TCPA.vue'
    import PhoneBox from './content/PhoneBox.vue'
    import TransitionLeftToRight from '../transitions/TransitionLeftToRight.vue'
    import { useFormPlanner } from '../../lib/compositions/useFormPlanner'

    export default defineComponent({
        name: 'Standard',
        components: {
            TransitionLeftToRight,
            FlowCircles,
            RequestImmediateAssistance,
            TCPA,
            PhoneBox,
        },
        props: {
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup() {

            const { currentStep, localStep, currentGroup } = useFormPlanner()

            const flow = computed<Flow>(() => ({
                step: localStep.value + 1,
                total: currentGroup.value.total,
            }))

            return {
                currentStep,
                flow,
            }

        },
    })

</script>
