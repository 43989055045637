<template>

    <footer class="bg-white border-t border-gray-200 text-xs"
            :class="{
                'py-2 mt-6': !isStoryBlokLandingActive,
                'hidden sm:block': isChat,
            }">

        <div class="max-w-7xl mx-auto px-4 overflow-hidden">

            <div class="flex flex-col md:flex-row items-center justify-between">

                <nav class="flex" aria-label="Footer">

                    <template v-for="(link, index) of footer.links" :key="index">

                        <template v-if="link.type === FooterLink.Modal && !link.data.hidden">

                            <div class="cursor-pointer hover:underline px-2 md:px-5 py-2"
                                 @click="showDynamicModal(link)">
                                {{ link.data.label }}
                            </div>

                        </template>

                        <template v-if="link.type === FooterLink.ExternalLink">

                            <a :href="link.data.url"
                               class="hover:underline block px-2 md:px-5 py-2"
                               target="_blank">
                                {{ link.data.label }}
                            </a>

                        </template>

                    </template>

                    <div key="contact"
                         class="cursor-pointer hover:underline px-2 md:px-5 py-2"
                         @click="showContactModal">
                        Contact Us
                    </div>

                </nav>

                <p class=" md:mr-5">
                    {{ footer.copyright }}
                </p>

            </div>

            <div class="mx-auto max-w-7xl text-justify border-t px-5 py-4 mt-4">
                <div v-for="(disclaimer, index) of disclaimers" :key="index" class="py-2" v-html="disclaimer.content"/>
            </div>

        </div>

    </footer>

    <ModalBase v-if="modal[ ModalType.Dynamic ]" max-width="sm:max-w-4xl" @close="destroy(ModalType.Dynamic)">
        <div class="markdown" v-html="dynamicModalContent"/>
    </ModalBase>

    <ModalContactUs v-if="modal[ ModalType.Contact ]" @close="destroy(ModalType.Contact)"/>

</template>

<script lang="ts">

    import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
    import ModalBase from '../modals/ModalBase.vue'
    import ModalContactUs from '../modals/ModalContactUs.vue'
    import { useRoute } from 'vue-router'
    import { isStoryBlokLandingActive, Modal, state } from '../../types/State'
    import { FooterLink } from '../../lib/enums/Footer'
    import { tcpa } from '../../types/TCPA'

    enum ModalType {
        Dynamic,
        Contact,
    }

    export default defineComponent({
        name: 'LayoutFooter',
        components: { ModalContactUs, ModalBase },
        setup() {

            const route = useRoute()
            const footer = state.domain.content.footer
            const disclaimers = computed(() => state.webForm.disclaimers.length ? state.webForm.disclaimers : state.domain.disclaimers)
            const dynamicModalContent = ref<string>()
            const isChat = computed(() => route.meta.isChat === true)

            const modal = reactive({
                [ ModalType.Contact ]: false,
                [ ModalType.Dynamic ]: false,
            })

            function destroy(type: ModalType): void {
                modal[ type ] = false
            }

            function showDynamicModal(link: Modal): void {

                dynamicModalContent.value = link.data.content
                modal[ ModalType.Dynamic ] = true

            }

            function showContactModal(): void {
                modal[ ModalType.Contact ] = true
            }

            function showDynamicModalById(id: string): void {

                if (id === 'contact') {
                    return showContactModal()
                }

                if (id === 'partners_list') {
                    return showTcpaPartnersListModal()
                }

                const link = footer.links.find(link => link.type === FooterLink.Modal && link.data.id === id) as Modal | undefined

                if (link) {
                    showDynamicModal(link)
                }

            }

            function showTcpaPartnersListModal(): void {

                dynamicModalContent.value = tcpa.partnersList
                modal[ ModalType.Dynamic ] = true

            }

            function openModalByRoute(): void {

                const id = route.hash.replace(new RegExp('^#'), '')

                if (!id) {
                    return
                }

                showDynamicModalById(id)

            }

            onMounted(() => setTimeout(() => openModalByRoute(), 400))

            return {
                isStoryBlokLandingActive,
                isChat,
                FooterLink,
                ModalType,
                modal,
                footer,
                disclaimers,
                dynamicModalContent,
                showDynamicModal,
                showContactModal,
                showDynamicModalById,
                destroy,
            }

        },
    })

</script>

<style lang="scss">

    .markdown p {
        @apply pb-4
    }

    .markdown h1 {
        @apply font-semibold
    }

    .markdown h2 {
        @apply font-semibold
    }

    .markdown h3{
        @apply font-semibold
    }

    .markdown h4 {
        @apply font-semibold
    }

    .markdown h5 {
        @apply font-semibold
    }

    .markdown a {
        @apply text-blue-500 underline
    }

</style>
