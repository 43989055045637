<template>

    <nav aria-label="Progress" class="flex-1">

        <ol role="list" class="flex flex-wrap items-center gap-y-4">

            <li v-for="step of steps"
                :key="step"
                class="relative"
                :class="{ 'pr-1 flex-1': step !== flow.total }">

                <div v-if="step !== flow.total" class="absolute inset-0 flex items-center" aria-hidden="true">

                    <div class="h-0.5 w-full bg-gray-300">

                        <div class="h-0.5 primary-bg transition-width duration-500"
                             :class="{ 'w-full': step <= flow.step, 'w-0': step >= flow.step }"/>

                    </div>

                </div>

                <div class="relative w-8 h-8 flex items-center justify-center rounded-full transition-all duration-200"
                     :class="{
                         'cursor-pointer': isDebugMode,
                         'primary-bg': step < flow.step,
                         'bg-white border-2 border-gray-300 delay-300': step > flow.step,
                         'border-2 primary-border bg-white': step === flow.step,
                     }"
                     @click="skip(step)">

                    <Icon v-if="step < flow.step" icon="mdi:check" class="w-5 h-5 text-white"/>

                    <div v-if="step >= flow.step" class="w-2.5 h-2.5 relative">

                        <div class="h-2.5 w-2.5 rounded-full absolute transition-colors delay-300 duration-200"
                             :class="{ 'primary-bg': step === flow.step }"
                             aria-hidden="true"/>

                        <div class="h-2.5 w-2.5 rounded-full absolute animate-ping transition-colors delay-300 duration-200"
                             :class="{ 'primary-bg': step === flow.step }"
                             aria-hidden="true"/>

                    </div>

                    <div class="sr-only">
                        Step {{ step }}
                    </div>

                </div>

            </li>

        </ol>

    </nav>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { Flow } from '../../types/Flow'
    import { range } from '../../lib/Helpers'
    import { Icon } from '@iconify/vue'
    import { isDebugMode } from '../../types/State'
    import { RouteKey } from '../../lib/enums/RouteKey'

    export default defineComponent({
        name: 'FlowCircles',
        components: { Icon },
        props: {
            flow: {
                type: Object as PropType<Flow>,
                required: true,
            },
        },
        setup(props) {

            const steps = computed<number[]>(() => range(1, props.flow.total))
            const router = useRouter()
            const route = useRoute()

            function skip(step: number): void {

                if (isDebugMode) {

                    router.push({
                        name: RouteKey.WebForm,
                        params: { ...route.params, step },
                        query: route.query,
                    })

                }

            }

            return {
                isDebugMode,
                skip,
                steps,
            }

        },
    })

</script>
